import { getShipmentForm } from '@components/pages/cart/utils';
import * as actions from '@constants/actions/cart';
import { mobileMode } from '@constants/config';
import { EPaymentProviders } from '@constants/index';
import { PageTypes } from '@constants/pageTypes';
import { RequestMethods } from '@constants/types';
import {
  cartGenerateByTransactionUrl,
  cartGetCountUrl,
  cartGetUrl,
  cartItemListUrl,
  cartItemUrl,
  cartUpdateUrl,
  getCustomerDeliveryMethodsUrl,
  getProductsByIdsUrl,
  orderCreateUrl,
  orderPaymentConfirmUrl,
  shipmentCountryUrl,
  shipmentLocationsUrl,
} from '@constants/urls';
import {
  ICartCoupon,
  ICartGiftCard,
  ICartItem,
  IOrderData,
  IOrderDataCart,
  IShipmentForm,
  ShipmentFormFields,
} from '@interfaces/cart';
import {
  ICartChangeForm,
  ICartChangePageAction,
  ICartChangeShipment,
  ICartCouponSetItem,
  ICartErrorClear,
  ICartGiftCardSetItem,
  ICartPaymentFailed,
  ICartPromoCodeRemove,
  ICartPromoCodeSet,
  ICartPromoCodeUpdateItem,
  ICartReMoveErrorToWarning,
  ICartTermsCheck,
  ICartUpdateShipmentFormAction,
  ICreateOrderPayload,
} from '@interfaces/cart/actions';
import { EPaymentStatus } from '@interfaces/cart/reducer';
import { IContextQuery } from '@interfaces/context/reducer';
import {
  ActionStatuses,
  ESessionStatuses,
  IApiErrorResponse,
  ICallApiAction,
  TCallApiAction,
} from '@interfaces/fetchService';
import {
  IApiResponse,
  IStoreActions,
  IThunkDispatch,
  TThunkAction,
  TThunkDispatch,
  TThunkResult,
} from '@interfaces/index';
import { IPageReference } from '@interfaces/pageData/reducer';
import { IProductInList } from '@interfaces/product';
import { IStore } from '@interfaces/store';
import {
  getBonusGiftCardsCookie,
  getBonusPromoCodesCookie,
  removeBonuses,
  setBonusCouponsCookie,
  setBonusGiftCardCookie,
} from '@utils/cookies';
import { getProductData } from '@utils/gtag';
import { getPageByType, pushOrReloadRoute } from '@utils/routes';
import i18nF from '@utils/translation';
import { GetServerSidePropsContext } from 'next';
import { CALL_AD, ICallADPayload } from '../../services/AnalyticEvent';
import { EAnalyticEventName } from '../../services/AnalyticEvent/types';

import { CALL_API } from '../../services/SSRService';
import { addParamsToUrl } from '../../services/SSRService/utils';
import { acAlertShowAction } from '../acAlert';

const translation = i18nF({});

interface IGetCartProps {
  country: string;
  lang: string;
  customerAuth?: string | undefined;
  shipmentMethodId?: number;
}

type TGetCart = (props: IGetCartProps) => any;
export const acCartGet: TGetCart = ({ country, lang, customerAuth, shipmentMethodId }) => ({
  [CALL_API]: {
    endpoint: addParamsToUrl(cartGetUrl(), { country, customerAuth, lang, shipmentMethodId }),
    method: RequestMethods.GET_METHOD,
    sessionId: {
      status: ESessionStatuses.dontCallWithoutSession,
    },
    types: [actions.CART_ITEMS_GET_REQUEST, actions.CART_ITEMS_GET_SUCCESS, actions.CART_ITEMS_GET_FAILED],
  },
});

export const acCartGetAction = () => (dispatch: IThunkDispatch<any, any, any>, getState: () => IStore) => {
  const {
    auth: { token },
    cart: { form: { shipmentMethodId } },
    pageData: { countryCode, languageCode },
  } = getState();
  if (countryCode && languageCode) {
    const requestParams: IGetCartProps = {
      country: countryCode,
      customerAuth: token?.access_token,
      lang: languageCode,
      shipmentMethodId: shipmentMethodId || undefined,
    };
    return dispatch(acCartGet(requestParams)).then((res) => {
      if (res.result && res.payload?.cartItems?.length === 0) {
        const giftCards = getBonusGiftCardsCookie(null, countryCode);
        if (giftCards) {
          removeBonuses(null, countryCode);
        }
      }
    });
  }
  return false;
};

interface IAddToCartProps extends IGetCartProps {
  request: ICartItem;
  cartValidate?: boolean;
}

type TAddToCart = (props: IAddToCartProps, adPayload?: ICallADPayload) => ICallApiAction;
export const acCartAddItem: TAddToCart = ({ country, lang, request, shipmentMethodId }, adPayload) => ({
  [CALL_AD]: {
    event: EAnalyticEventName.add_to_cart,
    payload: adPayload,
  },
  [CALL_API]: {
    body: { ...request, shipmentMethodId },
    endpoint: addParamsToUrl(cartItemUrl(), Object(request).hasOwnProperty('giftCardIssue') ? {
        cartValidate: true,
        country,
        lang,
      }
      : {
        country,
        lang,
      }),
    facebook: true,
    method: RequestMethods.POST_METHOD,
    sessionId: {
      status: ESessionStatuses.createSessionBefore,
    },
    types: [actions.CART_ADD_ITEM_REQUEST, actions.CART_ADD_ITEM_SUCCESS, actions.CART_ADD_ITEM_FAILED],
  },
});

export const acCartAddItemAction = (item: ICartItem, detailItemInfo?: IProductInList | null) => (
  dispatch,
  getState: () => IStore,
) => {
  const {
    cart: {
      form: { shipmentMethodId },
    },
    pageData: { countryCode, languageCode },
  } = getState();

  const selectedColor = detailItemInfo?.colors.find((colorsItem) => colorsItem.productId === item.productId);
  const productData = selectedColor ? getProductData(detailItemInfo, selectedColor) : {};
  if (countryCode && languageCode) {
    return dispatch(acCartAddItem({
      country: countryCode,
      lang: languageCode,
      request: item,
      shipmentMethodId: shipmentMethodId || undefined,
    }, { productData })).then((res) => {
      if (res.result) {
        dispatch(acAfterAddToCart());
        if (detailItemInfo) {
          dispatch(acCartAddDetailItemInfoAction(detailItemInfo));
        }
      } else {
        if (Array.isArray(res?.payload) && res.payload[0]?.errorCode === 5041) {
          dispatch(acAlertShowAction(translation.t('error.cart.limit')));
        }
      }
      return res;
    });
  }
};

export const acCartAddDetailItemInfoAction = (detailItemInfo: IProductInList) => ({
  payload: detailItemInfo,
  type: actions.CART_ADD_DETAIL_ITEM_INFO,
});

interface ITAddListToCartParams extends IGetCartProps {
  cartItems: ICartItem | ICartItem[];
}

type TAddListToCart = (
  params: ITAddListToCartParams,
) => TCallApiAction;

export const acCartAddItemList: TAddListToCart = ({
  country,
  lang,
  cartItems,
  customerAuth,
  shipmentMethodId,
}) => ({
  [CALL_API]: {
    body: { cartItems, shipmentMethodId },
    endpoint: addParamsToUrl(cartItemListUrl(), { country, customerAuth, lang }),
    facebook: true,
    method: RequestMethods.POST_METHOD,
    sessionId: {
      status: ESessionStatuses.createSessionBefore,
    },
    types: [actions.CART_ADD_ITEM_REQUEST, actions.CART_ADD_ITEM_SUCCESS, actions.CART_ADD_ITEM_FAILED],
  },
});

export const acCartAddItemListAction = (items: ICartItem[]) => (
  dispatch,
  getState: () => IStore,
) => {
  const {
    auth: { sessionId, token },
    cart: { form: { shipmentMethodId } },
    pageData: { countryCode, languageCode },
  } = getState();
  if (countryCode && languageCode && sessionId) {
    const params = {
      cartItems: items,
      country: countryCode,
      customerAuth: token?.access_token,
      lang: languageCode,
      sessionId,
      shipmentMethodId: shipmentMethodId || undefined,
    };
    return dispatch(acCartAddItemList(params)).then((res) => {
      if (res.result) {
        dispatch(acAfterAddToCart());
      } else {
        if (Array.isArray(res?.payload) && res.payload[0]?.errorCode === 5041) {
          dispatch(acAlertShowAction(translation.t('error.cart.limit')));
        }
      }
      return res;
    });
  }
  const reject: Promise<IApiResponse<any> | null> = Promise.resolve({
    payload: {},
    result: false,
    status: 0,
    type: 'A',
  });
  return reject;
};

interface IDeleteFromCartProps extends IGetCartProps {
  request: ICartItem;
}

type TDeleteFromCart = (
  params: IDeleteFromCartProps,
) => any;
export const acCartDeleteItem: TDeleteFromCart = ({
  country,
  lang,
  request,
  customerAuth,
  shipmentMethodId,
}) => ({
  [CALL_API]: {
    body: { ...request, shipmentMethodId },
    endpoint: addParamsToUrl(cartItemUrl(), { country, customerAuth, lang }),
    facebook: true,
    method: RequestMethods.DELETE_METHOD,
    sessionId: {
      status: ESessionStatuses.dontCallWithoutSession,
    },
    types: [actions.CART_DELETE_ITEM_REQUEST, actions.CART_DELETE_ITEM_SUCCESS, actions.CART_DELETE_ITEM_FAILED],
  },
});

export const acCartDeleteItemAction = (item: ICartItem): TThunkResult<Promise<IApiResponse<any> | null>> => (
  dispatch,
  getState: () => IStore,
) => {
  const {
    auth: { token },
    cart: {
      form: { discounts, shipmentMethodId },
    },
    pageData: { countryCode, languageCode },
  } = getState();
  if (countryCode && languageCode) {
    const params: IDeleteFromCartProps = {
      country: countryCode,
      customerAuth: token?.access_token,
      lang: languageCode,
      request: item,
      shipmentMethodId: shipmentMethodId || undefined,
    };
    return dispatch(acCartDeleteItem(params)).then(
      (response) => {
        if (response && response.result && discounts.length > 0) {
          dispatch(acCartUpdateAction());
        }
        return response;
      },
    );
  }
  return null;
};

interface IUpdateCartProps extends IGetCartProps {
  request: ICartItem;
}

type TacCartUpdateItem = (
  props: IUpdateCartProps,
) => any;
export const acCartUpdateItem: TacCartUpdateItem = ({
  country,
  lang,
  request,
  customerAuth,
  shipmentMethodId,
}) => ({
  [CALL_API]: {
    body: { ...request, shipmentMethodId },
    endpoint: addParamsToUrl(cartItemUrl(), Object(request).hasOwnProperty('giftCardIssue') ? {
        cartValidate: true,
        country, customerAuth, lang,
      }
      : { country, customerAuth, lang }),
    method: RequestMethods.PUT_METHOD,
    requestParams: request,
    sessionId: {
      status: ESessionStatuses.dontCallWithoutSession,
    },
    types: [actions.CART_UPDATE_ITEM_REQUEST, actions.CART_UPDATE_ITEM_SUCCESS, actions.CART_UPDATE_ITEM_FAILED],
  },
});

export const acCartUpdateItemAction = (item: ICartItem, qtyImprove?: boolean) => (
  dispatch,
  getState: () => IStore,
) => {
  const {
    auth: { token },
    cart: {
      form: { discounts, shipmentMethodId },
    },
    pageData: { countryCode, languageCode },
  } = getState();
  if (countryCode && languageCode) {
    const updateProps: IUpdateCartProps = {
      country: countryCode,
      customerAuth: token?.access_token,
      lang: languageCode,
      request: item,
      shipmentMethodId: shipmentMethodId || undefined,
    };
    return dispatch(acCartUpdateItem(updateProps)).then(
      (response) => {
        if (response && response.result && discounts.length > 0) {
          dispatch(acCartUpdateAction());
        }
        if (response && !response.result) {
          response.payload.map((error) => {
            if (error.errorCode === 4011 && !qtyImprove) {
              const availableQty = error?.fieldErrors ? error.fieldErrors[0].qty - error.fieldErrors[0].missingQty : 1;
              return dispatch(acCartUpdateItemAction({ ...item, qty: availableQty }, true)).then((res) => {
                dispatch(onMoveErrorToWarningAction(error));
                return res;
              });
            }
          });
        }
        return response;
      },
    );
  }
  return null;
};

export const onMoveErrorToWarningAction = (error: IApiErrorResponse) => (dispatch: TThunkDispatch) => {
  return dispatch({ type: actions.CART_MOVE_ERROR_TO_WARNING, payload: error });
};

export const onReMoveErrorToWarningAction = (error: IApiErrorResponse): ICartReMoveErrorToWarning => ({
  payload: error,
  type: actions.CART_REMOVE_ERROR_TO_WARNING,
});


export const onChangePageAction = (pageNumber: number): ICartChangePageAction => ({
  payload: pageNumber,
  type: actions.CART_CHANGE_PAGE,
});

export const acChangePagePrev = () => (dispatch, getState: () => IStore) => {
  const { cart } = getState();
  const isShipmentMethodDefault =
    cart.form.customerDeliveryMethod !== undefined && cart.form.customerDeliveryMethod !== null;
  if (cart.page === 3 && isShipmentMethodDefault) {
    return dispatch(onChangePageAction(1));
  } else {
    return dispatch(onChangePageAction(cart.page - 1));
  }
};

export const acChangePageNext = () => (dispatch, getState: () => IStore) => {
  const { cart } = getState();
  const isShipmentMethodDefault = cart.form.customerDeliveryMethod !== undefined && cart.form.customerDeliveryMethod !== null;
  if (cart.page === 1 && isShipmentMethodDefault && cart.form.shipmentPhone) {
    return dispatch(acCartUpdateAction(true)).then((res) => {
      if (res.result) {
        return dispatch(onChangePageAction(3));
      } else {
        return dispatch(onChangePageAction(cart.page + 1));
      }
    });

  } else {
    return dispatch(onChangePageAction(cart.page + 1));
  }
};

export const onChangeDeliveryMethodAction = (shipmentMethodId: number): ICartChangeShipment => ({
  payload: shipmentMethodId,
  type: actions.CART_CHANGE_SHIPMENT,
});

export const onUpdateShipmentFormAction: (
  form: Partial<IShipmentForm>,
  fields?: ShipmentFormFields[],
) => ICartUpdateShipmentFormAction = (form, fields = []) => ({
  fields,
  payload: form,
  type: actions.CART_SHIPMENT_FORM_UPDATE,
});

export const onChangePaymentMethodAction = (paymentId: string) => (dispatch) =>
  dispatch({
    payload: paymentId,
    type: actions.CART_CHANGE_PAYMENT,
  });

type TGetCartProducts = (
  countryCode: string,
  languageCode: string,
  productId: number[],
) => ICallApiAction;
export const acCartProducts: TGetCartProducts = (countryCode, languageCode, productId) => ({
  [CALL_API]: {
    endpoint: addParamsToUrl(getProductsByIdsUrl(), {
      country: countryCode,
      lang: languageCode,
      productId,
    }),
    facebook: true,
    method: RequestMethods.GET_METHOD,
    sessionId: {
      status: ESessionStatuses.dontCallWithoutSession,
    },
    types: [actions.CART_GET_PRODUCTS_REQUEST, actions.CART_GET_PRODUCTS_SUCCESS, actions.CART_GET_PRODUCTS_FAILED],
  },
});

type acCartProductsActionType = () => (dispatch, getState: () => IStore) => any;
export const acCartProductsAction: acCartProductsActionType = () => (dispatch, getState) => {
  const {
    cart: {
      form: { cartItems },
    },
    pageData: { countryCode, languageCode },
  } = getState();
  const productId = cartItems?.map((item) => item.productId);
  if (countryCode && languageCode && productId && productId.length > 0) {
    return dispatch(acCartProducts(countryCode, languageCode, productId));
  }
  return false;
};

interface ICartUpdateProps extends IGetCartProps {
  goToPayment?: boolean;
  request: IOrderDataCart;
  shipmentValidate: boolean;
}

type TCartUpdate = (
  props: ICartUpdateProps,
) => any;
export const acCartUpdate: TCartUpdate = ({
  country,
  goToPayment,
  lang,
  request,
  customerAuth,
  shipmentValidate,
}) => ({
  [CALL_API]: {
    body: request,
    endpoint: addParamsToUrl(cartUpdateUrl(), {
      country,
      customerAuth,
      goToPayment: goToPayment || Boolean(shipmentValidate),
      lang,
      paymentValidate: Boolean(request.paymentMethodId),
      shipmentValidate,
    }),
    method: RequestMethods.PUT_METHOD,
    sessionId: {
      status: ESessionStatuses.createSessionBefore,
    },
    status: {
      key: ActionStatuses.DISABLED_STATUS_BAR,
    },
    types: [actions.CART_UPDATE_REQUEST, actions.CART_UPDATE_SUCCESS, actions.CART_UPDATE_FAILED],
  },
});

interface IBonuses {
  giftCards?: ICartGiftCard[];
  promoCodes?: ICartCoupon[];
}

export const acCartUpdateAction: (shipmentValidate?: boolean, bonuses?: IBonuses) => TThunkResult<IApiResponse<any> | false> = (
  shipmentValidate = false,
  bonuses = {},
) => (dispatch, getState: () => IStore): Promise<IApiResponse<any> | false> => {
  const state = getState();
  const {
    auth: { token },
    pageData: { countryCode, languageCode },
  } = state;
  if (countryCode && languageCode) {
    const orderCartData: IOrderDataCart = getOrderData(state);
    if (orderCartData?.giftCards?.length < 1 && bonuses?.giftCards && bonuses?.giftCards?.length > 0) {
      orderCartData.giftCards = bonuses.giftCards;
    }
    if (orderCartData?.coupons?.length < 1 && bonuses?.promoCodes && bonuses?.promoCodes?.length > 0) {
      orderCartData.coupons = bonuses.promoCodes;
    }
    const params: ICartUpdateProps = {
      country: countryCode,
      customerAuth: token?.access_token,
      goToPayment: undefined, // TODO
      lang: languageCode,
      request: orderCartData,
      shipmentValidate,
    };
    return dispatch(acCartUpdate(params)).then((response) => {
      if (response.payload?.giftCards?.length > 0) {
        setBonusGiftCardCookie(JSON.stringify(response.payload.giftCards), countryCode);
      }
      if (response.payload?.coupons?.length > 0) {
        setBonusCouponsCookie(JSON.stringify(response.payload.coupons));
      }
      return response;
    });
  }
  return Promise.resolve(false);
};

type TOrderCreate = (
  countryCode: string,
  languageCode: string,
  request: IOrderData,
  customerAuth: string | undefined,
) => any;
export const acOrderCreate: TOrderCreate = (countryCode, languageCode, request, customerAuth) => ({
  [CALL_API]: {
    body: { ...request },
    endpoint: addParamsToUrl(orderCreateUrl(), {
      country: countryCode,
      customerAuth,
      lang: languageCode,
      purchaseSource: mobileMode ? 'app' : 'web',
      source: mobileMode ? 'app' : 'web',
    }),
    facebook: true,
    method: RequestMethods.POST_METHOD,
    sessionId: {
      status: ESessionStatuses.createSessionBefore,
    },
    types: [actions.ORDER_CREATE_REQUEST, actions.ORDER_CREATE_SUCCESS, actions.ORDER_CREATE_FAILED],
  },
});

export const acOrderCreateAction: (event?) => TThunkAction<Promise<IApiResponse<ICreateOrderPayload> | false>,
  IStore,
  null,
  IStoreActions> = (e) => async (dispatch, getState: () => IStore) => {
  const state = getState();
  const {
    auth: { token },
    pageData: { countryCode, languageCode, data: { pages } },
  } = state;
  if (countryCode && languageCode) {
    const orderCartData: IOrderDataCart = getOrderData(state);
    const orderData: IOrderData = {
      cart: orderCartData,
      saveTransport: false,
    };

    return dispatch(acOrderCreate(countryCode, languageCode, orderData, token?.access_token)).then((response) => {
      if (response && response.result && response.payload && response.payload.payment) {
        if (response.payload.payment?.status?.toUpperCase() === EPaymentStatus.successful) {
          const confirmPage: IPageReference | null = getPageByType(pages || [], PageTypes.order_confirmed);
          if (confirmPage) {
            let confirmPageUrl = confirmPage.url;
            if (!confirmPageUrl.startsWith('/')) {
              confirmPageUrl = '/' + confirmPageUrl;
            }
            if (!confirmPageUrl.endsWith('/')) {
              confirmPageUrl = confirmPageUrl + '/';
            }
            window.location.href = confirmPageUrl + response.payload.payment.transactionId || '';
          }
        } else if (response.payload.payment?.status?.toUpperCase() === EPaymentStatus.wait) {
          return response;
        }
      }
    });
  }
  return false;
};

type TShipmentLocation = (
  countryCode: string,
  languageCode: string,
  methodId: string | number,
) => ICallApiAction;
export const acShipmentLocation: TShipmentLocation = (countryCode, languageCode, methodId) => ({
  [CALL_API]: {
    endpoint: addParamsToUrl(shipmentLocationsUrl(), {
      country: countryCode,
      lang: languageCode,
      methodId,
    }),
    method: RequestMethods.GET_METHOD,
    types: [actions.CART_LOCATIONS_REQUEST, actions.CART_LOCATIONS_SUCCESS, actions.CART_LOCATIONS_FAILED],
  },
});

type acShipmentLocationActionType = (externalShipmentMethodId?: number) => (dispatch, getState: () => IStore) => any;
export const acShipmentLocationAction: acShipmentLocationActionType = (externalShipmentMethodId) => (
  dispatch,
  getState,
) => {
  const {
    cart: {
      form: { [ShipmentFormFields.shipmentMethodId]: shipmentMethodId },
    },
    pageData: { countryCode, languageCode },
  } = getState();

  const methodId = externalShipmentMethodId ? externalShipmentMethodId : shipmentMethodId;
  if (countryCode && languageCode && methodId) {
    return dispatch(acShipmentLocation(countryCode, languageCode, methodId));
  }
  return null;
};

type TShipmentCountry = (countryCode: string, languageCode: string) => ICallApiAction;
export const acShipmentCountry: TShipmentCountry = (countryCode, languageCode) => ({
  [CALL_API]: {
    endpoint: addParamsToUrl(shipmentCountryUrl(), {
      country: countryCode,
      lang: languageCode,
    }),
    method: RequestMethods.GET_METHOD,
    types: [actions.CART_COUNTRY_REQUEST, actions.CART_COUNTRY_SUCCESS, actions.CART_COUNTRY_FAILED],
  },
});

type acShipmentCountryActionType = () => (dispatch, getState: () => IStore) => any;
export const acShipmentCountryAction: acShipmentCountryActionType = () => (dispatch, getState) => {
  const {
    pageData: { countryCode, languageCode },
  } = getState();
  if (countryCode && languageCode) {
    return dispatch(acShipmentCountry(countryCode, languageCode));
  }
  return false;
};

const getOrderData = (store: IStore) => {
  const {
    cart: { form },
  } = store;
  const {
    amountBonusToUse,
    cartItems,
    coupons,
    customerDeliveryMethodId,
    giftCards,
    promotionCode,
    paymentMethodId,
    shipmentMethod,
    useBonus,
  } = form;
  const formShipment: IShipmentForm = getShipmentForm(form);
  const orderCartData: IOrderDataCart = {
    ...formShipment,
    amountBonusToUse,
    cartItems,
    coupons,
    giftCards,
    paymentMethodId,
    promotionCode,
    useBonus,
  };
  if (customerDeliveryMethodId) {
    orderCartData.customerDeliveryMethodId = customerDeliveryMethodId;
  }
  if (orderCartData[ShipmentFormFields.shipmentMethodId] === 0) {
    delete orderCartData[ShipmentFormFields.shipmentMethodId];
  }
  if (orderCartData[ShipmentFormFields.shipmentLocationId] === 0) {
    delete orderCartData[ShipmentFormFields.shipmentLocationId];
  }
  if (orderCartData[ShipmentFormFields.shipToCountryId] === 0) {
    delete orderCartData[ShipmentFormFields.shipToCountryId];
  }
  if (shipmentMethod) {
    if (!shipmentMethod.shipmentLocationRequired) {
      delete orderCartData[ShipmentFormFields.shipmentLocationId];
    }
    if (!shipmentMethod.addressRequired) {
      delete orderCartData[ShipmentFormFields.shipmentAddress];
      delete orderCartData[ShipmentFormFields.shipmentCity];
      delete orderCartData[ShipmentFormFields.shipmentZipcode];
    }
    if (!shipmentMethod.emailRequired) {
      delete orderCartData[ShipmentFormFields.shipmentEmail];
    }
    if (!shipmentMethod.phoneRequired) {
      delete orderCartData[ShipmentFormFields.shipmentPhone];
    }
  }
  return orderCartData;
};

export const onChangePromoCode = (promoCode: string): ICartPromoCodeSet => ({
  payload: promoCode,
  type: actions.CART_PROMOCODE_SET,
});

export const onSetCoupon = (couponToSet: ICartCoupon): ICartCouponSetItem => ({
  payload: couponToSet,
  type: actions.CART_COUPON_SETITEM,
});

export const onSetGiftCard = (giftCardToSet: ICartGiftCard): ICartGiftCardSetItem => ({
  payload: giftCardToSet,
  type: actions.CART_GIFTCARD_SETITEM,
});

export const onUpdateBonus = (bonusToUpdate: ICartGiftCard): ICartPromoCodeUpdateItem => ({
  payload: bonusToUpdate,
  type: actions.CART_PROMOCODE_UPDATE_ITEM,
});

export const onRemovePromoCode = (promoCode: string): ICartPromoCodeRemove => ({
  payload: promoCode,
  type: actions.CART_PROMOCODE_REMOVE,
});

type TGetCustomerDeliveryMethods = (params: { country: string, lang: string }) => ICallApiAction;
export const acGetCustomerDeliveryMethods: TGetCustomerDeliveryMethods = ({ country, lang }) => ({
  [CALL_API]: {
    endpoint: addParamsToUrl(getCustomerDeliveryMethodsUrl(), { country, lang }),
    method: RequestMethods.GET_METHOD,
    types: [
      actions.CART_CUSTOMER_DELIVERY_METHODS_REQUEST,
      actions.CART_CUSTOMER_DELIVERY_METHODS_SUCCESS,
      actions.CART_CUSTOMER_DELIVERY_METHODS_FAILED,
    ],
  },
  type: '',
});

type acGetCustomerDeliveryMethodsActionType = () => (dispatch, getState: () => IStore) => any;
export const acGetCustomerDeliveryMethodsAction: acGetCustomerDeliveryMethodsActionType = () => (dispatch, getState) => {
  const { pageData: { countryCode, languageCode } } = getState();
  return dispatch(acGetCustomerDeliveryMethods({ country: countryCode || 'EE', lang: languageCode || 'et' }));
};

export const acCartInit = (context?: GetServerSidePropsContext) => async (dispatch, getState: () => IStore) => {
  const { pageData: { countryCode } } = getState();
  // if (!loaded || cartItems?.length < 1) {
  await dispatch(acCartGetAction());
  await dispatch(acCartProductsAction());
  // }
  const bonuses: Partial<IBonuses> = {};
  const giftCards = getBonusGiftCardsCookie(context || null, countryCode || 'EE');
  if (giftCards) {
    const giftCardsArray = JSON.parse(giftCards);
    if (Array.isArray(giftCardsArray) && giftCardsArray.length > 0) {
      bonuses.giftCards = giftCardsArray;
    }
  }
  const promoCodes = getBonusPromoCodesCookie(context || null);
  if (promoCodes) {
    const promoCodesArray = JSON.parse(promoCodes);
    if (Array.isArray(promoCodesArray) && promoCodesArray.length > 0) {
      bonuses.promoCodes = promoCodesArray;
    }
  }
  if (bonuses.giftCards?.length || bonuses.promoCodes?.length) {
    await dispatch(acCartUpdateAction(false, bonuses));
  }
};

export const acCartInitFailed = (id: string) => async (dispatch): Promise<boolean> => {
  dispatch(acCartPaymentFailed(id));
  const restoredCart = await dispatch(acCartGenerateByTransactionAction());
  if (restoredCart && restoredCart.result) {
    dispatch(acCartProductsAction());
    await dispatch(acCartUpdateAction());
  }
  return Promise.resolve(true);
};

export const acCartInitPaymentConfirm = () => (dispatch) => {
  return dispatch(onChangePageAction(4));
};

export const acCartChangeForm = (form): ICartChangeForm => ({
  payload: form,
  type: actions.CART_CHANGE_FORM,
});

export const acCartPaymentFailed = (id): ICartPaymentFailed => ({
  payload: id,
  type: actions.CART_PAYMENT_FAILED,
});

export const acCartTermsCheck = (checked: boolean): ICartTermsCheck => ({
  payload: checked,
  type: actions.CART_TERMS,
});

type TCartGenerateByTransaction = (
  transactionId: string,
  params: { country: string; lang: string; },
) => ICallApiAction;
export const acCartGenerateByTransaction: TCartGenerateByTransaction = (
  transactionId,
  { country, lang },
) => ({
  [CALL_API]: {
    endpoint: addParamsToUrl(cartGenerateByTransactionUrl(transactionId), {
      country,
      lang,
    }),
    method: RequestMethods.GET_METHOD,
    sessionId: {
      status: ESessionStatuses.createSessionBefore,
    },
    types: [
      actions.CART_GENERATE_BY_TRANSACTION_REQUEST,
      actions.CART_GENERATE_BY_TRANSACTION_SUCCESS,
      actions.CART_GENERATE_BY_TRANSACTION_FAILED,
    ],
  },
});

type TCartGenerateByTransactionType = () => (dispatch, getState: () => IStore) => any;
export const acCartGenerateByTransactionAction: TCartGenerateByTransactionType = () => (dispatch, getState) => {
  const {
    cart: {
      payment: { orderUID },
    },
    pageData: { countryCode, languageCode },
  } = getState();
  if (orderUID && languageCode && countryCode) {
    return dispatch(acCartGenerateByTransaction(orderUID, { country: countryCode, lang: languageCode }));
  } else {
    return false;
  }
};

type TOrderPaymentConfirm = (query: IContextQuery) => ICallApiAction;
export const acOrderPaymentConfirm: TOrderPaymentConfirm = (query) => {
  const { json, mac, ...queryParams } = query;
  return {
    [CALL_API]: {
      body: json
        ? {
          json: window.btoa(unescape(encodeURIComponent(json))),
          mac,
        }
        : { mac },
      endpoint: addParamsToUrl(orderPaymentConfirmUrl(), queryParams),
      method: RequestMethods.POST_METHOD,
      types: [
        actions.ORDER_PAYMENT_CONFIRM_REQUEST,
        actions.ORDER_PAYMENT_CONFIRM_SUCCESS,
        actions.ORDER_PAYMENT_CONFIRM_FAILED,
      ],
    },
  };
};

type acOrderPaymentConfirmActionType = () => (dispatch, getState: () => IStore) => any;
export const acOrderPaymentConfirmAction: acOrderPaymentConfirmActionType = () => (dispatch, getState) => {
  const { context: { query } } = getState();

  if (query) {
    const { params, provider = EPaymentProviders.maksekeskus, ...queryToApi } = query;
    const data: any = { ...queryToApi };
    if (provider === EPaymentProviders.modena) {
      const body = {
        id: query.id,
        orderId: query.orderId,
      };
      data.json = JSON.stringify(body);
      if (query.id) {
        data.mac = query.id;
      }
    }
    return dispatch(acOrderPaymentConfirm(data));
  }

  return false;
};

export const acOrderConfirmedInit = () => async (dispatch, getState: () => IStore) => {
  await dispatch(onChangePageAction(4));
  const {
    context: {
      query: { params },
    },
    pageData: { countryCode, languageCode },
  } = getState();
  const id = (params && params.length === 4 && params[3]) || null;
  if (id && languageCode && countryCode) {
    return dispatch(acCartGenerateByTransaction(id, { country: countryCode, lang: languageCode }));
  } else {
    return false;
  }
};

type TCartGetCount = (params) => ICallApiAction;
export const acCartGetCount: TCartGetCount = (params) => ({
  [CALL_API]: {
    endpoint: addParamsToUrl(cartGetCountUrl(), params),
    method: RequestMethods.GET_METHOD,
    sessionId: {
      status: ESessionStatuses.dontCallWithoutSession,
    },
    types: [actions.CART_COUNT_REQUEST, actions.CART_COUNT_SUCCESS, actions.CART_COUNT_FAILED],
  },
});

export const acCartGetCountAction = () => (dispatch, getState: () => IStore) => {
  const { pageData: { countryCode, languageCode } } = getState();
  const locale = { country: countryCode || '', lang: languageCode || '' };
  return dispatch(acCartGetCount({ ...locale }));
};

const acAfterAddToCart = () => (dispatch, getState: () => IStore) => {
  const { pageData: { data: { pages } } } = getState();
  const cartPage = pages ? getPageByType(pages, PageTypes.cart) : null;
  if (cartPage) {
    dispatch(acAlertShowAction(translation.t('common.itemWasAddedToCart'),
      translation.t('common.itemWasAddedToCartAction'),
      () => pushOrReloadRoute(cartPage.url)));
  } else {
    dispatch(acAlertShowAction(translation.t('common.itemWasAddedToCart')));
  }
};

export const acCartErrorClear = (): ICartErrorClear => ({
  type: actions.CART_ERROR_CLEAR,
});

export const acChangeDelivery = (shipmentMethodId) => async (dispatch, getState: () => IStore) => {
  const { cart: { form: { shipmentMethods } } } = getState();
  await dispatch(onChangeDeliveryMethodAction(shipmentMethodId));
  dispatch(acCartUpdateAction());
  const selectedShipmentMethod = shipmentMethods.find(
    (methodItem) => methodItem.shipmentMethodId === shipmentMethodId,
  );
  if (selectedShipmentMethod && selectedShipmentMethod.shipmentLocationRequired && !selectedShipmentMethod.locations?.length) {
    dispatch(acShipmentLocationAction());
  }
};

export const acCartPhoneValidate = (params) => ({
  [CALL_API]: {
    endpoint: addParamsToUrl(cartGetCountUrl(), params),
    method: RequestMethods.POST_METHOD,
    sessionId: {
      status: ESessionStatuses.canCallWithoutSession,
    },
    types: [actions.CART_COUNT_REQUEST, actions.CART_COUNT_SUCCESS, actions.CART_COUNT_FAILED],
  },
});
